<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-2">
        {{ $t(CREATE_PAGE_TITLE_PREFIX) }}
        —
        {{ getValueFromGivenObjectByKey(assetItem, 'product.sku', '') }}
        {{ getValueFromGivenObjectByKey(assetItem, 'product.name', '') }}
      </h3>
    </portal>
    <request-service-quote-form v-if="order.id && !isLoading" />
    <skelton v-else />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import RequestServiceQuoteForm from '../components/create/request-service-quote/Form.vue'
import Skelton from '../components/create/request-service-quote/components/Skelton.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'RequestServiceQuote',
  components: {
    QuoteStatusBar,
    Skelton,
    RequestServiceQuoteForm,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    serviceOrderInformationFormDefaultValue() {
      return this.$store.state[this.MODULE_NAME].serviceOrderInformationFormDefaultValue
    },
    assetItem() {
      return this.$store.state[this.MODULE_NAME].assetItem
    },
  },
  created() {
    this.isLoading = true
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_ORDER_INFORMATION_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_ATTACHMENTS`, [])
    this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_TYPE_ON_CHANGE`, 'send-out')

    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params.id).then(({ data }) => {
      const order = data
      this.$store.dispatch(`${this.MODULE_NAME}/getTestDamagedAsset`, { id: data.inventory_item_damaged.inventory_item_id })
      // eslint-disable-next-line no-shadow
        .then(({ data: { data } }) => {
          this.$store.commit(`${this.MODULE_NAME}/GET`, order)
          this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_ORDER_INFORMATION_FORM`, {
            customer_id: order.customer_id,
            billing_contact_id: order?.billing_contact && { ...getValueFromGivenObjectByKey(order, 'billing_contact', ''), fullName: `${getValueFromGivenObjectByKey(order, 'billing_contact.first_name', '')} ${getValueFromGivenObjectByKey(order, 'billing_contact.last_name', '')}` },
            billing_email: getValueFromGivenObjectByKey(order, 'billing_contact.email', ''),
            billing_phone: getValueFromGivenObjectByKey(order, 'billing_contact.phone_one', ''),
            billing_address: order?.billing_address && `${getValueFromGivenObjectByKey(order, 'billing_address.street', '')}, ${getValueFromGivenObjectByKey(order, 'billing_address.line2', '')}, ${getValueFromGivenObjectByKey(order, 'billing_address.city', '')}, ${getValueFromGivenObjectByKey(order, 'billing_address.state.name', '')}, ${getValueFromGivenObjectByKey(order, 'billing_address.zip', '')}, ${getValueFromGivenObjectByKey(order, 'billing_address.country?.name', '')}`,
            internal_account_notes: getValueFromGivenObjectByKey(order, 'customer.customer_detail.comments', ''),
            payment_term_id: getValueFromGivenObjectByKey(order, 'payment_term', ''),
            internal_order_reference: getValueFromGivenObjectByKey(order, 'internal_order_reference', ''),
            internal_notes: getValueFromGivenObjectByKey(order, 'internal_notes', ''),
          })

          this.$store.commit(`${this.MODULE_NAME}/SET_ATTACHMENTS`, [...order.attachments])

          this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {
            dispatch_warehouse_id: getValueFromGivenObjectByKey(order, 'dispatch_warehouse', ''),
            dispatch_method_id: getValueFromGivenObjectByKey(order, 'dispatch_method', getValueFromGivenObjectByKey(order, 'dispatch_method_id', '')),
            return_method_id: getValueFromGivenObjectByKey(order, 'return_method', getValueFromGivenObjectByKey(order, 'return_method_id', '')),
            return_warehouse_id: getValueFromGivenObjectByKey(order, 'return_warehouse', ''),
            internal_dispatch_notes: getValueFromGivenObjectByKey(order, 'internal_shipping_notes', ''),
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    })
  },
  setup() {
    const {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
    } = config()

    return {
      MODULE_NAME,
      quoteStatusBar,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
      getValueFromGivenObjectByKey,
    }
  },
}
</script>
